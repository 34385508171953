import React from "react"
import PropTypes from "prop-types"

// Components
import { graphql } from "gatsby"
import Layout from "../components/layout"

import * as styles from "../styles/categories.module.scss"
import CategoryList from "../components/categorylist"
import { createCategoryNameMap } from "../components/functions/createconst"
import CreateList from "../components/createlist"

const Allposts = ({ pageContext, data, location }) => {


    const categorynamemap = createCategoryNameMap()

    const category = pageContext.category
    const posts = data.Allposts.nodes
    const siteTitle = data.site.siteMetadata.title
    const categoryHeader = `全ての投稿`
    const categorygroup = data.categorygroup.group
    const totalCount = data.Allposts.totalCount


    return (
        <Layout location = {location} title = {siteTitle}>
            <div className={styles.bodywrapper}>
                <h1 className = {styles.categorytitle}>{categoryHeader}</h1>

                <CategoryList categorygroup={categorygroup}></CategoryList>

                <div>
                    全部で{totalCount}件の投稿があります
                </div>

                <CreateList posts = {posts}></CreateList>        
            </div>
        </Layout>
    )
}

Allposts.propTypes = {
    pageContext: PropTypes.shape({
        
        category: PropTypes.string.isRequired,
    }),
    data: PropTypes.shape({
    allMdx: PropTypes.shape({
        totalCount: PropTypes.number.isRequired,
        edges: PropTypes.arrayOf(
        PropTypes.shape({
            node: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
                slug: PropTypes.string.isRequired,
            }),
            }),
        }).isRequired
        ),
    }),
    }),
}

export default Allposts

export const pageQuery = graphql`
query {
    site{
        siteMetadata{
            title
        }
    }

    categorygroup: allMdx(limit: 2000) {
        group(field: frontmatter___category2) {
            fieldValue
            totalCount
        }
    }

    Allposts: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {frontmatter: {pagetype: {eq: "blog"}}}
    ) {
        nodes {
            id
            fields {
                slug
            }
            frontmatter {
                category2
                category1
                date(formatString: "MMMM DD, YYYY")
                description
                title
                tags
                hero{
                    childImageSharp{
                        gatsbyImageData(
                            width: 600
                            placeholder: NONE
                        )
                    }
                }
            }
        }
        totalCount
    }
}
`